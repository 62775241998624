// extracted by mini-css-extract-plugin
export var servicesDevExpertiseCompany = "c3_xW";
export var servicesDevExpertiseCompany__card = "c3_xX";
export var servicesDevExpertiseCompany__emoji = "c3_xZ";
export var servicesDevExpertiseCompany__maxWidth140 = "c3_x1";
export var servicesDevExpertiseCompany__maxWidth180 = "c3_x2";
export var servicesDevExpertiseCompany__maxWidth190 = "c3_x3";
export var servicesDevExpertiseCompany__maxWidth270 = "c3_x4";
export var servicesDevExpertiseCompany__maxWidth310 = "c3_x5";
export var servicesDevExpertiseCompany__text = "c3_x0";
export var servicesDevExpertiseCompany__title = "c3_xY";